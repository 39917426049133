const full_name_config = {
    label: "Full Name/पूरा नाम:",
    type: "text",
    name: "name",
    id: "name",
    validation: {
        required: {
            value: true,
            message: "required",
        },
    },
    page: "personal-details",
};

const dob_config = {
    label: "What is your Date of Birth (DoB)?/जन्म की तारीख",
    type: "date",
    name: "date_of_birth",
    id: "date_of_birth",
    validation: {
        required: {
            value: true,
            message: "required",
        },
    },
    page: "personal-details",
};

const pan_card_config = {
    label: "PAN Card Number/पैन कार्ड नंबर:",
    type: "text",
    name: "pan_card",
    id: "pan_card",
    validation: {
        required: {
            value: true,
            message: "required",
        },
        pattern: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
    },
    page: "personal-details",
};

const perm_address_config = {
    label: "Permanent Address/स्थायी पता:",
    type: "text",
    name: "perm_address",
    id: "perm_address",
    validation: {
        required: {
            value: true,
            message: "required",
        },
    },
    page: "personal-details",
};

const vehicle_model_config = {
    label: "Vehicle Model / वाहन मॉडल (Optional)",
    type: "text",
    name: "vehicle_model",
    id: "vehicle_model",
    validation: {
        required: {
            value: false,
            message: "optional",
        },
    },
    page: "personal-details",
};

const perm_address_pincode_config = {
    label: "Permanent Pincode/ स्थायी पिनकोड",
    type: "number",
    name: "perm_address_pincode",
    id: "perm_address_pincode",
    validation: {
        required: {
            value: true,
            message: "required",
        },
    },
    page: "personal-details",
};

const estimated_vehicle_amount_config = {
    label: "Estimated Loan Amount / अनुमानित ऋण राशि",
    type: "number",
    name: "estm_loan_amount",
    id: "estm_loan_amount",
    validation: {
        required: {
            value: true,
            message: "required",
        },
    },
    page: "personal-details",
};

const vehicle_assistance_config = {
    label: "Have you checked out a vehicle or do you require assistance with the same?/क्या आपने वाहन देखा है या आपको इसमें सहायता की आवश्यकता है?",
    type: "select",
    name: "vehicle_assistance",
    id: "vehicle_assistance",
    validation: {
        required: {
            value: true,
            message: "Please select an option/कृपया एक विकल्प चुनें",
        },
    },
    page: "personal-details",
    placeholder: "Have you checked out a vehicle or do you require assistance with the same?/क्या आपने वाहन देखा है या आपको इसमें सहायता की आवश्यकता है?",
    options: [
        { label: "I need help in selecting the vehicle / मुझे वाहन चुनने में मदद चाहिए", value: true },
        { label: "I know the vehicle model I want / मुझे पता है कि मुझे कौन सा वाहन मॉडल चाहिए", value: false },
    ],
};

const house_config = {
    label: "House is owned or rented/ मकान स्वामित्व का है अथवा किराये का है",
    type: "select",
    name: "house_type",
    id: "house_type",
    validation: {
      required: {
        value: true,
        message: "required",
      },
    },
    placeholder: "Whether the house is owned or rented / मकान स्वामित्व का है अथवा किराये का है",
    options: [
      { value: "Owned", label: "Owned / स्वामित्व" },
      { value: "Rented", label: "Rented / किराये पर" },
    ],
    page: "personal-details",
  };


export const twformFields = {
    quess: [
        full_name_config,
        pan_card_config,
        dob_config,
        perm_address_config,
        perm_address_pincode_config,
        house_config,
        vehicle_assistance_config,
        // estimated_vehicle_amount_config,
        // vehicle_model_config
    ]
}