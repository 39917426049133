import React, { useState, useRef } from 'react';
import { Formik, Form } from 'formik';
import { toast } from "react-toastify";
import * as Yup from 'yup';
import Webcam from 'react-webcam';

const DocUpload = (props) => {
  const [showWebcam, setShowWebcam] = useState(false);
  const [capturedImage, setCapturedImage] = useState(null);
  const [facingMode, setFacingMode] = useState("user");
  const webcamRef = useRef(null);

  // Define required documents based on company
  const getRequiredDocuments = () => {
    return {
      pan: 'PAN card image is required',
      selfie: 'Selfie is required',
    };
  };

  const validationSchema = Yup.object().shape(
    Object.fromEntries(
      Object.entries(getRequiredDocuments()).map(([key, value]) => [
        key,
        Yup.mixed().required(value)
      ])
    )
  );

  const handleCaptureSelfie = (setFieldValue) => {
    const imageSrc = webcamRef.current.getScreenshot();
    setCapturedImage(imageSrc);
    // Don't set field value yet, wait for confirmation
  };

  const handleConfirmSelfie = (setFieldValue) => {
    setFieldValue('selfie', capturedImage);
    // setShowWebcam(false);
    // setCapturedImage(null);
  };

  const handleRetake = () => {
    setCapturedImage(null);
  };

  const handleFileChange = (event, setFieldValue, field) => {
    const file = event.currentTarget.files[0];
    setFieldValue(field, file);
  };

  const toggleCamera = () => {
    setFacingMode(prevMode =>
      prevMode === "user" ? "environment" : "user"
    );
  };

  const convertFileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleSubmit = async (values) => {
    try {
      const documentUploads = {
        pan: values.pan instanceof File
          ? await convertFileToBase64(values.pan)
          : values.pan,
        selfie: values.selfie instanceof File
          ? await convertFileToBase64(values.selfie)
          : values.selfie,
      };

      if (props?.redirectToStep) {
        props.redirectToStep(documentUploads);
      } else {
        console.error("redirectToStep prop is not defined");
        toast.error("Submission handler is not configured properly");
      }
    } catch (error) {
      console.error('Error converting images:', error);
      toast.error('Error uploading documents. Please try again.');
    }
  };

  const getInitialValues = () => {
    return {
      pan: null,
      selfie: null,
    };
  };

  return (
    <div className="max-w-2xl mx-auto p-6">
      <h2 className="text-2xl font-bold mb-6">Document Upload / ಡಾಕ್ಯುಮೆಂಟ್‌ಗಳ ಅಪ್‌ಲೋಡ್</h2>

      <Formik
        initialValues={getInitialValues()}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({ errors, touched, setFieldValue, values, isSubmitting }) => (
          <Form className="space-y-6">
            {/* pan */}
            <div>
              <label className="block mb-2">PAN Card</label>
              <p className="text-sm text-gray-500">Upload the photo of PAN Card </p>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleFileChange(e, setFieldValue, 'pan')}
                className="w-full p-2 border rounded"
              />
              {errors.pan && touched.pan && (
                <div className="text-red-500 text-sm mt-1">{errors.pan}</div>
              )}
            </div>

            <div>
              <label className="block mb-2">Selfie</label>
              <p className="text-sm text-gray-500">Upload your selfie photo or click on the below button to take a selfie from your phone camera.</p>
              {!showWebcam ? (
                <div className="space-y-2">
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileChange(e, setFieldValue, 'selfie')}
                    className="w-full p-2 border rounded"
                  />
                  <button
                    type="button"
                    onClick={() => setShowWebcam(true)}
                    className="bg-[#4E05FF] text-white px-4 py-2 rounded w-full"
                  >
                    Take Selfie with Webcam
                  </button>
                </div>
              ) : (
                <div className="space-y-4">
                  {capturedImage ? (
                    <div className="space-y-4">
                      <img
                        src={capturedImage}
                        alt="Captured selfie"
                        className="w-64 h-64 object-cover mx-auto rounded"
                      />
                      <div className="flex justify-center gap-4">
                        <button
                          type="button"
                          onClick={() => handleConfirmSelfie(setFieldValue)}
                          className="bg-green-500 text-white px-6 py-2 rounded"
                        >
                          Confirm
                        </button>
                        <button
                          type="button"
                          onClick={handleRetake}
                          className="bg-yellow-500 text-white px-6 py-2 rounded"
                        >
                          Retake
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setShowWebcam(false);
                            setCapturedImage(null);
                          }}
                          className="bg-red-500 text-white px-6 py-2 rounded"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="space-y-4">
                      <div className="relative">
                        <Webcam
                          ref={webcamRef}
                          screenshotFormat="image/jpeg"
                          className="w-full max-w-md mx-auto rounded"
                          videoConstraints={{
                            facingMode: facingMode,
                            width: 1280,
                            height: 720
                          }}
                        />
                        <button
                          type="button"
                          onClick={toggleCamera}
                          className="absolute top-2 right-2 bg-gray-800 text-white p-2 rounded-full opacity-70 hover:opacity-100"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                            />
                          </svg>
                        </button>
                      </div>
                      <div className="flex justify-center gap-4">
                        <button
                          type="button"
                          onClick={() => handleCaptureSelfie(setFieldValue)}
                          className="bg-green-500 text-white px-6 py-2 rounded"
                        >
                          Capture
                        </button>
                        <button
                          type="button"
                          onClick={() => setShowWebcam(false)}
                          className="bg-red-500 text-white px-6 py-2 rounded"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {errors.selfie && touched.selfie && (
                <div className="text-red-500 text-sm mt-1">{errors.selfie}</div>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-[#4E05FF] text-white py-2 px-4 rounded hover:bg-blue-700 disabled:bg-gray-400"
            >
              {isSubmitting ? 'Submitting...' : 'Submit Documents'}
            </button>
          </Form>

        )}

      </Formik>
    </div>
  );
};

export default DocUpload;
